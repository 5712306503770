import React, { useState } from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import styled from 'styled-components';
import ProductBreadcrumb from '../components/ProductBreadcrumb';
import ProductMain from '../components/ProductMain';
import MoreSlider from '../components/MoreSlider';
import ProductDrawer from '../components/ProductDrawer';
import SEO from '../components/SEO';
import ProductCard from '../components/ProductCard';

const ProductStyled = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 3rem;
  .related-products-wrapper {
    margin-top: 3rem;
    padding: 0 2rem;
    h4 {
      margin-bottom: 2rem;
    }
  }
  .related-more-products {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 2rem;
    @media (max-width: 1023px) {
      grid-template-columns: 1fr;
    }
  }
`;

export default function SingleProductPage({ data }) {
  const { product, disciplines, series } = data;
  return (
    <>
      <SEO
        title={product.title}
        image={product.productImage.asset.fluid.src}
        description={product.description}
      />
      <ProductStyled>
        <div className="wrapper">
          <ProductBreadcrumb
            series={product.series}
            discipline={product.discipline}
          />
          <ProductMain
            title={product.title}
            description={product._rawProductDescription}
            image={product.productImage.asset.fluid}
            variants={product.variants}
            slug={product.slug.current}
            series={product.series.slug.current}
            collection={
              product.title.includes('Custom') ? 'custom' : 'non-custom'
            }
          />
          {product.productGallery.length > 0 && (
            <ProductDrawer title="Gallery" gallery={product.productGallery} />
          )}
          {product.featureGallery.length > 0 && (
            <ProductDrawer title="Features" gallery={product.featureGallery} />
          )}
          {product.techSpecs.length > 0 && (
            <ProductDrawer title="Tech Specs" content={product.techSpecs} />
          )}
          {product.spareParts.length > 0 && (
            <ProductDrawer title="Spare Parts" parts={product.spareParts} />
          )}
        </div>
        <div className="wrapper more-products">
          {disciplines.nodes.length > 2 && (
            <MoreSlider products={disciplines.nodes} />
          )}
          {disciplines.nodes.length <= 2 && (
            <div className="related-products-wrapper">
              <h4>{`Discover more ${product.discipline.title} products`}</h4>
              <div className="related-more-products">
                {disciplines.nodes.map((item) => (
                  <ProductCard
                    title={item.title}
                    description={item.description}
                    image={item.productImage.asset.fluid}
                    series={item.series.title}
                    discipline={item.discipline.title}
                    slug={item.slug.current}
                  />
                ))}
              </div>
            </div>
          )}
        </div>
      </ProductStyled>
    </>
  );
}

export const query = graphql`
  query ($slug: String!, $series: String!, $discipline: String!) {
    product: sanityProducts(slug: { current: { eq: $slug } }) {
      title
      id
      description
      _rawProductDescription
      slug {
        current
      }
      discipline {
        id
        title
        slug {
          current
        }
      }
      series {
        id
        title
        slug {
          current
        }
      }
      productImage {
        asset {
          fluid(maxWidth: 1000) {
            ...GatsbySanityImageFluid
          }
        }
      }
      productGallery {
        image {
          asset {
            fluid(maxWidth: 1000, maxHeight: 700) {
              ...GatsbySanityImageFluid
            }
          }
        }
        caption
      }
      featureGallery {
        image {
          asset {
            fluid(maxWidth: 1000, maxHeight: 700) {
              ...GatsbySanityImageFluid
            }
          }
        }
        heading
        caption
      }
      variants {
        colorFirst
        colorSecond
        title
        href
        variantGallery {
          ... on SanityImage {
            asset {
              fluid(maxWidth: 1000) {
                ...GatsbySanityImageFluid
              }
            }
          }
          ... on SanityFile {
            asset {
              url
              mimeType
            }
          }
        }
      }
      techSpecs {
        title
        details
      }
      spareParts {
        productImage {
          asset {
            fluid(maxWidth: 1000) {
              ...GatsbySanityImageFluid
            }
          }
        }
        title
        slug {
          current
        }
      }
    }
    disciplines: allSanityProducts(
      filter: { discipline: { slug: { current: { eq: $discipline } } } }
    ) {
      nodes {
        collection {
          slug {
            current
          }
          title
        }
        discipline {
          slug {
            current
          }
          title
        }
        series {
          slug {
            current
          }
          title
        }
        productImage {
          asset {
            fluid(maxWidth: 1000) {
              ...GatsbySanityImageFluid
            }
          }
        }
        title
        slug {
          current
        }
        description
      }
    }
    series: allSanityProducts(
      filter: { series: { slug: { current: { eq: $series } } } }
    ) {
      nodes {
        discipline {
          slug {
            current
          }
          title
        }
        series {
          slug {
            current
          }
          title
        }
        productImage {
          asset {
            fluid(maxWidth: 1000) {
              ...GatsbySanityImageFluid
            }
          }
        }
        title
        slug {
          current
        }
        description
      }
    }
  }
`;
