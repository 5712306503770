import React, { useEffect, useState } from 'react';
import {
  CarouselProvider,
  Slider,
  Slide,
  Image,
  ButtonBack,
  ButtonNext,
  DotGroup,
} from 'pure-react-carousel';
import { FiChevronRight } from '@react-icons/all-files/fi/FiChevronRight';
import { FiChevronLeft } from '@react-icons/all-files/fi/FiChevronLeft';
import styled from 'styled-components';
import Img from 'gatsby-image';
import ProductCard from './ProductCard';

function useWindowSize() {
  const [size, setSize] = useState([0, 0]);
  useEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  return size;
}

export default function MoreSlider(props) {
  const [width, height] = useWindowSize();
  return (
    <CarouselProvider
      naturalSlideWidth={100}
      totalSlides={props.products.length}
      visibleSlides={width > 1023 ? 3 : 1}
      currentSlide={1}
      infinite
    >
      <div className="more-products-header">
        <h4>
          Discover more {`${props.products[0].discipline.title}`} products
        </h4>
        <div className="container slider-controls">
          <ButtonBack>
            <FiChevronLeft />
          </ButtonBack>
          <ButtonNext>
            <FiChevronRight />
          </ButtonNext>
        </div>
      </div>
      <Slider className="more-products-list">
        {props.products.map((product, index) => (
          <Slide index={index} className="card-slide">
            <ProductCard
              title={product.title}
              description={product.description}
              image={product.productImage.asset.fluid}
              series={product.series.title}
              discipline={product.discipline.title}
              slug={product.slug.current}
            />
          </Slide>
        ))}
      </Slider>
    </CarouselProvider>
  );
}
